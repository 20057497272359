import React from 'react'
import moment from 'moment'
import { first, get, isEmpty, keyBy, round } from 'lodash'
import * as mtz from 'moment-timezone'
import NumberFormat from 'react-number-format'

export const formatQuarter = (quarter) => {
  if (quarter === 1) {
    return '1st Quarter'
  }
  if (quarter === 2) {
    return '2nd Quarter'
  }
  if (quarter === 3) {
    return '3rd Quarter'
  }
  return '4th Quarter'
}

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

export const formatPhone = (phoneNumber) => {
  if (!/^\d{10}$/.test(phoneNumber)) {
    throw new Error('The phone number must have 10 numeric digits')
  }

  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export const formatDate = (date, format) => {
  if (!date || typeof date === 'undefined') {
    return 'N/A'
  }
  const formattedDate = moment(date)
    .utc()
    .format(format || 'M/D/YYYY')
  if (formattedDate === 'Invalid date') {
    return '--'
  }
  return formattedDate
}

export const formatParagraphs = (text) => {
  let fullText = ''
  text.forEach((item) => {
    fullText += `<p>${item}</p>`
  })
  return fullText.replace(/(?:\\[rn]|[\r\n]+)+/g, '</p><p>')
}

export const formatPercentage = (value) => {
  return `${parseFloat(round(value * 100, 2)).toFixed(2)}%`
}

export const formatPercentOneDecimal = (value) => {
  return parseFloat(round(value * 100, 1)).toFixed(1)
}

export const dollarFormatter = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: '2',
})

export const formatWithCommas = (value, decimals) => {
  if (decimals !== undefined) {
    return Number(value).toLocaleString('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
  }

  return Number(value).toLocaleString('en-US')
}

export const formatThousand = (value, digits) => {
  return (value / 1000).toLocaleString('en-US', {
    maximumFractionDigits: digits,
  })
}

export const formatHundredThousand = (value, digits) => {
  return (value / 1000000).toLocaleString('en-US', {
    maximumFractionDigits: digits,
  })
}

export const formatDollars = (value, digits = 2) => {
  return new Intl.NumberFormat('en-us', {
    style: 'decimal',
    minimumFractionDigits: digits,
  }).format(value)
}

export const formatMillion = (value, digits = 2) => {
  return (value / 1000000).toLocaleString('en-US', {
    maximumFractionDigits: digits,
  })
}

export const formatDollarsWithSymbol = (value, digits = 2) => {
  return new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
  }).format(value)
}

export const hasSideBar = (also) => {
  return also.title || also.html || also.links || also.css_class
}

// New function suitable for new site codebase. Check PriceTable.js for example implementation
export const changeIndicator = (value) => {
  if (value > 0) {
    return 'fundChangeUp'
  }
  if (value < 0) {
    return 'fundChangeDown'
  }
  return ''
}

export const heroTitleStyle = (titleColor) => {
  return titleColor ? { color: `${titleColor}` } : { color: '#2274a4' }
}

export function dividendDayText({
  totalDividend,
  recordDateCalendar: { date },
}) {
  return DIVIDENDS_DAY_POPOVER_TEXT.replace(
    '%{value}',
    `$${totalDividend}`
  ).replace('%{date}', formatDate(date))
}

// Changed to reflect new path for products
export function formatLinkWithTicker(row) {
  const ticker = row.ticker?.toLowerCase()
  const productType = get(
    row,
    'share_class[0].product_type',
    'fund'
  ).toLowerCase()

  return `/${productType}/${ticker}`
}

export function formatLinkWithFund(fund, tab = 'overview') {
  return fund ? `${fund.default_url}/${tab}` : ''
}

export const fundCompositeValueRenderer = (props) => (
  <span>
    {typeof props.value === 'number'
      ? formatPercentOneDecimal(props.value)
      : '--'}
  </span>
)

export const shouldSplitInTwoColumns = (yields, tab) => {
  return yields && yields.length > 0 && typeof tab !== 'undefined'
}

export const buildYieldWithNames = (yields, popups, labelList) => {
  const popover = keyBy(popups, (p) => p.field_name)

  const yieldsWithNames = []
  for (const y in yields) {
    // skip calendar field
    if (y !== 'calendar' && yields[y] && labelList[y]) {
      yieldsWithNames.push({
        yieldType: y,
        name: labelList[y],
        value: formatPercentage(yields[y]),
        popover: popover[y],
      })
    }
  }

  return yieldsWithNames
}

export const collectDocumentsFromGroup = (documentGroup) => {
  // TODO this seems sloppy, but works
  if (isEmpty(documentGroup)) {
    return null
  }

  const result = []
  Object.values(first(documentGroup)).forEach((documentChunk) => {
    Object.values(documentChunk).forEach((doc) => {
      if (Array.isArray(doc)) {
        result.push(...doc)
      } else {
        result.push(doc)
      }
    })
  })
  return result
}

export function getDocUrl(document) {
  return get(first(document), 'document.publicDocURL')
}

export const getMonthDisplay = (monthName) => {
  return `${monthName}${monthName.toLowerCase() !== 'may' ? '.' : ''}`
}

export const grabComponentFromObject = (obj = {}) => {
  if (typeof obj !== 'object' || obj === null) {
    return null
  }

  const key = Object.keys(obj).find(
    (prop) => obj[prop] !== null && obj[prop] !== undefined
  )

  if (key) {
    return {
      componentType: key,
      componentProps: obj[key],
    }
  }

  return null
}

export const getBusinessHours = () => {
  mtz.tz.setDefault('America/Chicago')
  const actualHour = moment().format('H')

  if (moment().isoWeekday() >= 1 && moment().isoWeekday() <= 5) {
    if (Number(actualHour) >= 8 && Number(actualHour) <= 18) {
      return true
    }
  }

  return false
}

// function that returns number with siffix. ex: 1th, 2nd
export const ordinalSuffixOf = (number) => {
  if (number) {
    const lastDigit = number % 10
    const exceptions = number % 100
    if (lastDigit === 1 && exceptions !== 11) {
      return `${number}st`
    }
    if (lastDigit === 2 && exceptions !== 12) {
      return `${number}nd`
    }
    if (lastDigit === 3 && exceptions !== 13) {
      return `${number}rd`
    }
    return `${number}th`
  }
  return undefined
}

export const isWindowDefined = () => typeof window !== 'undefined'

export const isNumber = (e) => {
  const re = /^[0-9\b]+$/
  return e.target.value === '' || re.test(e.target.value)
}

export const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      onValueChange={(values) => {
        onChange({
          target: {
            value: String(values.value),
            name: other.name,
          },
        })
      }}
      type="tel"
    />
  )
}

export const formatUrlPath = (path) => {
  return path
    .replace(',', '')
    .replace('.', '')
    .replace(/["]/g, '')
    .replace(/\\"/g, '')
    .replace(/\s/g, '-')
    .toLowerCase()
}

export const cleanString = (text) => {
  return text.replace(/[^a-zA-Z0-9-]/g, '')
}

export const buildSessionStorageFilterKey = (
  activeShareClassId,
  currentFilterId
) => {
  return `${activeShareClassId}-${currentFilterId}-selected`
}

// Percentage string expecte to be in format: 70% and such
export function percentageToDecimal(percentage) {
  const numericValue = parseFloat(percentage.replace('%', ''))
  return numericValue / 100
}

export function hexToRgba(hex, opacity = 1) {
  const newHex = hex.replace('#', '')

  const r = parseInt(newHex.slice(0, 2), 16)
  const g = parseInt(newHex.slice(2, 4), 16)
  const b = parseInt(newHex.slice(4, 6), 16)

  const newOpacity = Math.min(1, Math.max(0, opacity))

  return `rgba(${r}, ${g}, ${b}, ${newOpacity})`
}
